/* LOCAL  */
// export const LINK = 'http://front.telesaude.local:8080';
// export const DOMAIN = 'meet.inovadora.com.br';
// export const BACK = 'https://telebacklocal.inovadora.com.br/api/';

/* SERVIDOR DE TESTES  */
// export const LINK = 'https://telesaude.g-mus.mobi';
// export const DOMAIN = 'https://telesaudeteste.inovadora.com.br';
// export const BACK = 'https://telesaudeteste.g-mus.mobi';

/*  BRUSQUE  */
//export const LINK = 'https://telesaude.smsbrusque.sc.gov.br';
//export const DOMAIN = 'meet.inovadora.com.br';
//export const BACK = 'https://backend.smsbrusque.sc.gov.br/api/';
//export const DOMAIN = 'jitsy.smsbrusque.sc.gov.br';

/*  Rio Grande  */
// export const LINK = 'https://telesaude.riogrande.rs.gov.br';
// export const DOMAIN = 'meet2.inovadora.com.br';
// export const BACK = 'https://backtelesaude.riogrande.rs.gov.br/api/';
//export const DOMAIN = 'jitsy.smsbrusque.sc.gov.br';

/*  INOVADORA  */
/*
export const LINK = 'https://telesaude.inovadora.com.br';
export const DOMAIN = 'meet.eplansoft.com.br';
export const BACK = 'https://telesaudeback.inovadora.com.br/api/';
*/


/*  EPLANSOFT  */
//export const LINK = 'https://consulta.eplansoft.com.br';
//export const DOMAIN = 'meet.eplansoft.com.br';
//export const BACK = 'https://tele.eplansoft.com.br/api/';


/*  IOSC */
//export const LINK = 'http://consultaonline.iosc-br.com/;
//export const DOMAIN = 'meet.inovadora.com.br';
//export const BACK = http://consultaonline.iosc-br.com/
//Endereço da API de Autienticação do IOSC
//        -http://api.iosc-br.com
//        -http://apiteste.iosc-br.com

/*Taquara*/
// export const LINK = 'https://telesaude-taquara.inovadorasistemas.com.br';
// export const DOMAIN = 'meet-taquara.inovadorasistemas.com.br';
// export const BACK = 'https://telesaudeback-taquara.inovadorasistemas.com.br/api/';

/* Igrejinha */
export const LINK = 'https://telesaude-igrejinha.inovadorasistemas.com.br';
export const DOMAIN = 'meet.inovadorasistemas.com.br';
export const BACK = 'https://telesaudeback-igrejinha.inovadorasistemas.com.br/api/';
