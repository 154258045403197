import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGIN_LINK = "loginLink";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const BUSCA_FORMAS = "buscaFormas";
export const SET_PERMITE_NOTIFICACOES = "setPermiteNotificacoes";
export const ADD_FILE = "addArquivo";
export const FECHA_SALA = "fechaSala";
export const RESET = "resetState";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_FORMAS = "setFormas";
export const SET_SALA = "setSala";

const state = {
	errors: {},
	user: {
		nome: ''
	},
	participante: sessionStorage.getItem('participante') ? JSON.parse(sessionStorage.getItem('participante')) : null,
	formas: [],
	parametros: sessionStorage.getItem('parametros') ? JSON.parse(sessionStorage.getItem('parametros')) : {},
	permiteNotificacoes: null,
	sala: sessionStorage.getItem('sala') ? JSON.parse(sessionStorage.getItem('sala')) : null,
	salas: [],
	//arquivos: sessionStorage.getItem('arquivos') ? JSON.parse(sessionStorage.getItem('arquivos')) : [],
	arquivos: [],
	agendamentos: [],
	isAuthenticated: !!JwtService.getToken() || JSON.parse(sessionStorage.getItem('sala'))!=null
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	getFormas(state) {
		return state.formas;
	},
	getParametros(state) {
		return state.parametros;
	},
	getSala(state) {
		return state.sala;
	},
	getParticipante(state) {
		return state.participante;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	},
	getSalas(state) {
		return state.salas;
	},
	getArquivos(state) {
		return state.arquivos;
	},
	getAgendamentos(state) {
		return state.agendamentos;
	}
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise(resolve => {
			ApiService.post("login", credentials)
				.then(({data}) => {
					//console.log(data);
					if (data.status == true) {
						context.commit(SET_AUTH, data);
						resolve(data);
					} else {
						alert('Não foi possivel logar')
					}
				})
				.catch(({response}) => {
					if (response) {
						context.commit(SET_ERROR, response.data.errors);
					}
				});
		});
	},
	[LOGIN_LINK](context, credentials) {
		return new Promise(resolve => {
			ApiService.get("p", credentials)
				.then(({data}) => {
					//console.log(data);
					if (data.status == true) {
						context.commit(SET_AUTH, data);
						resolve(data);
					} else {
						alert(data.error)
					}
				})
				.catch(({response}) => {
					if (response) {
						context.commit(SET_ERROR, response.data.errors);
					}
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[REGISTER](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post("users", {user: credentials})
				.then(({data}) => {
					context.commit(SET_AUTH, data);
					resolve(data);
				})
				.catch(({response}) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[VERIFY_AUTH](context) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			return new Promise((resolve, reject) => {
				ApiService.get("verify")
					.then(({data}) => {
						context.commit(SET_AUTH, data);
						resolve(state.user);
					})
					.catch(({response}) => {
						context.commit(SET_ERROR, response.data.errors);
						reject({response})
					});
			});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[UPDATE_USER](context, payload) {
		const {email, username, password, image, bio} = payload;
		const user = {email, username, bio, image};
		if (password) {
			user.password = password;
		}

		return ApiService.put("user", user).then(({data}) => {
			context.commit(SET_AUTH, data);
			return data;
		});
	},
	[BUSCA_FORMAS](context, payload) {
		return ApiService.get('formas').then((resp) => {
			context.commit(SET_FORMAS, resp.data.formas);
		});
	},
	[SET_SALA](context, payload) {
		context.commit(SET_SALA, payload);
	},
	[SET_PERMITE_NOTIFICACOES](context, payload) {
		context.commit(SET_PERMITE_NOTIFICACOES, payload);
	},
	[ADD_FILE](context, payload){
		context.commit(ADD_FILE, payload);
	},
	[FECHA_SALA](context, payload){
		context.commit(FECHA_SALA, {})
	},
	[RESET](context){
		context.commit(RESET, {})
	}
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state, data) {
		state.parametros = data.parametros;
		state.isAuthenticated = true;
		if (data.user) {
			state.user = data.user;
			state.formas = data.formas;
			state.salas = data.salas;
			state.agendamentos = data.agendamentos;
			state.parametros = data.parametros;
			state.errors = {};
			JwtService.saveToken(state.user.token);
		} else {
			//console.log(data);
			//alert(data.participante.sala);
			state.isAuthenticated = true;
			sessionStorage.setItem('sala', JSON.stringify(data.participante.sala));
			sessionStorage.setItem('participante', JSON.stringify(data.participante));
			sessionStorage.setItem('parametros', JSON.stringify(data.parametros));
			state.sala = data.participante.sala;
			state.parametros = data.parametros;
			state.participante = data.participante;
			state.arquivos = data.participante.arquivos;
		}
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {
			nome: ''
		};
		state.errors = {};
		state.formas = [];
		state.sala = null;
		sessionStorage.clear();
		JwtService.destroyToken();
	},
	[SET_FORMAS](state, data) {
		state.formas = data;
	},
	[SET_SALA](state, payload) {
		sessionStorage.setItem('sala', JSON.stringify(payload));
		state.sala = payload;
		state.salas.unshift(payload);
	},
	[SET_PERMITE_NOTIFICACOES](state, payload) {
		state.permiteNotificacoes = payload;
	},
	[ADD_FILE](state, payload) {
		state.arquivos.unshift(payload);
		console.log(state.arquivos);
		//sessionStorage.setItem('arquivos',JSON.stringfy(state.arquivos))
	},
	[FECHA_SALA](state, payload){
		sessionStorage.removeItem('sala');
		state.sala = null;
		state.participante = null;
	},
	[RESET](state, payload){
		sessionStorage.removeItem('sala');
		state.arquivos = [];
		state.sala = null;
		state.participante = null;
	},
};

export default {
	state,
	actions,
	mutations,
	getters
};
